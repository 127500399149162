import { atom, DefaultValue, selector } from "recoil"

export type Scopes =
  | "profile.name"
  | "profile.titles"
  | "profile.gender"
  | "profile.birthdate"
  | "profile.birthnumber"
  | "profile.birthplaceNationality"
  | "profile.maritalstatus"
  | "profile.addresses"
  | "profile.idcards"
  | "profile.paymentAccounts"
  | "profile.email"
  | "profile.phonenumber"
  | "profile.updatedat"
  | "profile.legalstatus"
  | "profile.zoneinfo"
  | "profile.locale"
  | "notification.claims_updated"
  | "offline_access"

export type ProfileScopes =
  | "name"
  | "titles"
  | "gender"
  | "birthdate"
  | "birthnumber"
  | "birthplaceNationality"
  | "maritalstatus"
  | "addresses"
  | "idcards"
  | "paymentAccounts"
  | "email"
  | "phonenumber"
  | "updatedat"
  | "legalstatus"
  | "zoneinfo"
  | "locale"

export type IdPortError = "temporarily_unavailable" | "server_error"

export type Consents = {
  mandatoryConsents: Partial<Record<Scopes, unknown>> // TODO typings for each
  requiredConsents: Partial<Record<Scopes, unknown>> // TODO typings for each
}

export type DocumentDetails =
  | "id"
  | "documentHash"
  | "title"
  | "subject"
  | "language"
  | "created"
  | "author"
  | "size"
  | "read_by_enduser"
  | "uri"
  | "page_count"
  | "appName"
  | "providerName"
  | "signRequestId"

export type DocumentsToSign = Partial<Record<DocumentDetails, unknown>>[]

export const aDocumentsToSign = atom<DocumentsToSign>({
  key: "aDocumentsToSign",
  default: [],
})

export type ObjectSignDetails =
  | "signObject"
  | "signObjectHash"
  | "created"
  | "signRequestId"

export type ObjectToSign = Partial<Record<ObjectSignDetails, unknown>>

export const aObjectToSign = atom<ObjectToSign>({
  key: "aObjectToSign",
  default: {},
})

type IdPortValue = string | undefined

export type IdPortData = {
  token: IdPortValue
  state: IdPortValue
  tracestate: IdPortValue
}

/**
 * token received from initial redirect
 */
export const aIdPortToken = atom<IdPortValue>({
  key: "aIdPortToken",
  default: undefined,
})

/**
 * state received from initial redirect
 */
export const aIdPortState = atom<IdPortValue>({
  key: "aIdPortState",
  default: undefined,
})

/**
 * tracestate received from initial redirect
 */
export const aIdPortTraceState = atom<IdPortValue>({
  key: "aIdPortTraceState",
  default: undefined,
})

export const sIdPortInit = selector<IdPortData>({
  key: "sIdPortInit",
  get: ({ get }) => {
    return {
      token: get(aIdPortToken),
      state: get(aIdPortState),
      tracestate: get(aIdPortTraceState),
    }
  },
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      return
    }

    const { token, state, tracestate } = value

    set(aIdPortToken, token)
    set(aIdPortState, state)
    set(aIdPortTraceState, tracestate)
  },
})

/**
 * Consents received from postLogin request
 */
export const aConsents = atom<Consents>({
  key: "aConsents",
  default: {
    mandatoryConsents: {},
    requiredConsents: {},
  },
})

/**
 * Consents given by the user
 */
export const aConsentsGiven = atom<string[]>({
  key: "aConsentsGiven",
  default: [],
})

/**
 * Utility to check if any consents need to be displayed
 */
export const sNumberOfConsents = selector({
  key: "sNumberOfConsents",
  get: ({ get }) => {
    const { mandatoryConsents, requiredConsents } = get(aConsents)

    return [...Object.keys(mandatoryConsents), ...Object.keys(requiredConsents)]
      .length
  },
})

/**
 * Merges plain envelope attributes to an object
 */

export const aEnvelopeName = atom<IdPortValue>({
  key: "aEnvelopeName",
  default: undefined,
})

export const aEnvelopeHash = atom<IdPortValue>({
  key: "aEnvelopeHash",
  default: undefined,
})
