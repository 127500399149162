import { FC, useRef } from "react"
import { useRecoilValue } from "recoil"

import { ReactComponent as Info } from "../../assets/infoGreen.svg"
import {
  aDocumentsToSign,
  aEnvelopeHash,
  aEnvelopeName,
  aObjectToSign,
} from "../../state/idport"
import { ReactComponent as ArrowDown } from "../../assets/arrow-down.svg"
import FormattedObjectSign, {
  FormattedObjectSignProps,
} from "../format/sign/formatted-object-sign"
import FormattedRequestBlock, {
  FormattedRequestBlockProps,
} from "../format/sign/formatted-request-block"
import FormattedSingleDocumentSign from "../format/sign/formatted-single-document-sign"
import FormattedMultiDocumentSign, {
  FormattedMultiDocumentSignProps,
} from "../format/sign/formatted-multi-document-sign"
import {
  aPreloginAppName,
  aPreloginProviderName,
  aSignRequestId,
} from "../../state/global"

const BankSign: FC = () => {
  const documents = useRecoilValue(aDocumentsToSign)
  const { signObject, signObjectHash, signRequestId } =
    useRecoilValue(aObjectToSign)
  const envelopeName = useRecoilValue(aEnvelopeName)
  const envelopeHash = useRecoilValue(aEnvelopeHash)
  const appName = useRecoilValue(aPreloginAppName)
  const providerName = useRecoilValue(aPreloginProviderName)
  const globalSignRequestID = useRecoilValue(aSignRequestId)

  const hiddenDivRef = useRef<HTMLDivElement>(null)

  const requestBlockData: FormattedRequestBlockProps["data"] = {
    providerName,
    appName,
    signRequestId: signRequestId ? String(signRequestId) : globalSignRequestID,
  }

  const objectSignData: FormattedObjectSignProps["data"] = signObject
    ? {
        signObject: signObject
          ? (signObject as Record<string, string>)
          : undefined,
        signObjectHash: signObjectHash ? String(signObjectHash) : undefined,
      }
    : undefined

  const multiDocuments: FormattedMultiDocumentSignProps["data"]["documents"] =
    []

  if (documents) {
    documents.forEach(({ title, subject, uri, page_count, id, created }) => {
      multiDocuments.push({
        title: title ? String(title) : undefined,
        subject: subject ? String(subject) : undefined,
        uri: uri ? String(uri) : undefined,
        pageCount: Number(page_count),
        id: id ? String(id) : undefined,
      })
      const keyValues = {
        createdDate: created,
      }
      Object.keys(keyValues).forEach(key => {
        if (
          !requestBlockData[key as keyof typeof requestBlockData] &&
          keyValues[key as keyof typeof keyValues]
        ) {
          requestBlockData[key as keyof typeof requestBlockData] = String(
            keyValues[key as keyof typeof keyValues]
          )
        }
      })
    })
  }

  return (
    <>
      <h1 className="text-lg font-bold pb-3 mb-5 border-b border-solid border-black-da">
        Pokyn k elektronickému podpisu
      </h1>
      <div className="overflow-y-auto h-full sm:pr-3">
        <FormattedRequestBlock data={requestBlockData} />

        {multiDocuments.length === 1 && (
          <FormattedSingleDocumentSign
            data={{
              title: documents[0].title
                ? String(documents[0].title)
                : undefined,
              documentHash: documents[0].documentHash
                ? String(documents[0].documentHash)
                : undefined,
              subject: documents[0].subject
                ? String(documents[0].subject)
                : undefined,
              uri: documents[0].uri ? String(documents[0].uri) : undefined,
              pageCount:
                typeof documents[0].page_count === "number"
                  ? documents[0].page_count
                  : undefined,
            }}
          />
        )}
        {multiDocuments.length > 1 && (
          <FormattedMultiDocumentSign
            data={{ envelopeHash, envelopeName, documents: multiDocuments }}
          />
        )}

        <FormattedObjectSign data={objectSignData} />
        <div className="sm:hidden -mt-12 flex justify-center pb-1 z-10">
          <button
            onClick={() => {
              window.scrollTo({
                top: hiddenDivRef?.current?.offsetTop,
                behavior: "smooth",
              })
            }}
            className="flex items-center rounded-full bg-green-brand py-2 pl-2"
          >
            <ArrowDown /> <div className="pr-4 text-smaller">Potvrdit</div>
          </button>
        </div>
        <div className="flex items-center bg-black-f5 py-3 px-2 w-full text-xs rounded-md border-solid border-black-da border mt-3">
          <Info className="mr-3 w-10" />
          Pokračováním dáváte pokyn Společnosti Bankovní identita, a.s., aby
          připojila váš elektronický podpis
        </div>
        <div ref={hiddenDivRef} />
      </div>
    </>
  )
}

export default BankSign
