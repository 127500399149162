import { FC } from "react"
import FormattedSignRow from "./formatted-sign-row"

type ObjectSignData = {
  signObjectHash: string
  signObject: Record<string, string>
}

export type FormattedObjectSignProps = {
  data?: Partial<ObjectSignData>
}

const FormattedObjectSign: FC<FormattedObjectSignProps> = ({ data }) => {
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const { signObject, signObjectHash } = data

  return (
    <div className="p-5 pb-2 flex flex-col text-xs mb-3 border border-solid border-black-da rounded-md">
      {signObject && (
        <FormattedSignRow label="Podepisovaný obsah">
          <div className="font-medium">
            {Object.keys(signObject).map(consent => {
              return (
                <p className="pb-3" key={consent}>
                  {consent}
                  {signObject[consent] && (
                    <span className="inline-block font-bold">
                      &nbsp;- {signObject[consent]}
                    </span>
                  )}
                </p>
              )
            })}
          </div>
        </FormattedSignRow>
      )}
      {signObjectHash && (
        <FormattedSignRow label="Otisk prohlášení">
          {signObjectHash}
        </FormattedSignRow>
      )}
    </div>
  )
}

export default FormattedObjectSign
